import './styles.css'
import LOGO from './gmi_logo_taupe.svg'
import { ClickCounter } from './ClickCounter'

export const App = () => {
  return (
    <>
      <h1>React Typescript Webpack Starter Template</h1>
      <div>
        <img src={LOGO} alt="Lola Logo1 something else" width="300" />
      </div>
      <div>
        <ClickCounter />
      </div>
    </>
  )
}
